import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';

const DashboardCard = ({ title, children, icon }) => (
  <div className="bg-white p-4 rounded-lg shadow-md">
    <h2 className="text-lg font-semibold mb-2 flex items-center">
      {icon}
      <span className="ml-2">{title}</span>
    </h2>
    {children}
  </div>
);

const ProjectGreenDashboard = () => {
  const [data, setData] = useState({
    signalAccuracy: { correct: 0, total: 0 },
    stockSignals: {}
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://104.236.1.29/data.json');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Fetch every minute
    return () => clearInterval(interval);
  }, []);

  const accuracyPercentage = data.signalAccuracy.total > 0
    ? (data.signalAccuracy.correct / data.signalAccuracy.total * 100).toFixed(2)
    : 'N/A';

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <h1 className="text-2xl font-bold mb-4">projectGreen Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <DashboardCard title="Overall Signal Accuracy" icon={<CheckCircle className="w-5 h-5 text-green-500" />}>
          <p className="text-3xl font-bold">{accuracyPercentage}%</p>
          <p className="text-sm text-gray-500">({data.signalAccuracy.correct} / {data.signalAccuracy.total} signals)</p>
        </DashboardCard>
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Stock Signals</h2>
        {Object.entries(data.stockSignals).map(([ticker, info]) => (
          <div key={ticker} className="mb-4 bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold">{ticker}</h3>
            <p>Accuracy: {((info.correct / info.total) * 100).toFixed(2)}%</p>
            <table className="min-w-full mt-2">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-sm leading-normal">
                  <th className="py-2 px-4 text-left">Date</th>
                  <th className="py-2 px-4 text-left">Type</th>
                  <th className="py-2 px-4 text-left">Result</th>
                </tr>
              </thead>
              <tbody>
                {info.signals.map((signal, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="py-2 px-4">{signal.date}</td>
                    <td className="py-2 px-4">{signal.type}</td>
                    <td className="py-2 px-4">
                      <span className={signal.result === 'correct' ? 'text-green-500' : 'text-red-500'}>
                        {signal.result}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectGreenDashboard;